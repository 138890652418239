import React from "react";
import { string, bool, shape } from "prop-types";
import { motion } from "framer-motion";
import { isMobileOnly } from "react-device-detect";
import Title from "../../Base/Title";
import CtaPrimary from "../../Base/CtaPrimary";
import AppetizeImage from "../../../components/ImageQuerys/AppetizeImages";
import useStartMotion from "../../../hooks/use-start-motion";
import background from "../../../images/svg/vector-09.svg";

const slideLeftVariants = {
  hidden: { opacity: 0, x: -50 },
  visible: {
    opacity: 1,
    x: 0,
  },
};

const slideRightVariants = {
  hidden: { opacity: 0, x: 50 },
  visible: {
    opacity: 1,
    x: 0,
  },
};

const HighlightWithImageAndOffsetText = ({
  className,
  title,
  subtext,
  ctaPrimary,
  imageName,
  flipItems,
  dark,
  animations,
}) => {
  const { ref, controls } = useStartMotion();

  // const darkClasses = "pt-28 lg:pt-56 pb-20 lg:pb-40";
  const darkClasses = "pt-28 lg:pt-56";

  return (
    <section
      data-cy="highlight-with-image-and-offset-text"
      className={`
        relative w-full
        ${dark ? "bg-black overflow-hidden pb-16 lg:pb-0" : ""}
        ${className}
      `}
      ref={ref}
    >
      {!flipItems && (
        <img
          src={background}
          alt="background"
          className="absolute left-0 -bottom-20 transform scale-0 lg:scale-100"
        />
      )}
      {flipItems && (
        <img
          src={background}
          alt="background"
          className="absolute right-0 -top-80 transform scale-0 lg:scale-100 rotate-180"
        />
      )}
      <div
        className={`
          relative max-w-1440 w-full mx-auto
          grid lg:grid-cols-2 lg:gap-x-11
          px-4 lg:px-10 xl:px-0
          ${dark ? darkClasses : ""}
        `}
      >
        {/* Title and body */}
        <motion.div
          className={`
          flex flex-col lg:items-end
          order-2 lg:order-${!flipItems ? "1" : "2"}
          mt-10 lg:mt-0
          `}
          animate={controls}
          initial={isMobileOnly || !animations ? "visible" : "hidden"}
          variants={slideLeftVariants}
          transition={{ delay: 0.6, duration: 0.8 }}
        >
          <Title
            className={`
            xl:max-w-50-percent
            xl:mr-36
            ${dark ? "" : "lg:-mt-14"}
            ${dark ? "text-white" : "text-black"}
            `}
            level={2}
            withBlueSymbol
          >
            {title}
          </Title>

          <div className="xl:max-w-50-percent">
            <p
              className={`text-p ${dark ? "text-black-600" : "text-black-200"}`}
            >
              {subtext}
            </p>

            {ctaPrimary && (
              <CtaPrimary
                className="mt-6 lg:t-8"
                title={ctaPrimary.title}
                target={ctaPrimary.target}
              />
            )}
          </div>
        </motion.div>

        {/* Image */}
        <motion.div
          className={`order-1 lg:order-${
            !flipItems ? "2" : "1"
          } max-w-80-percent lg:max-w-100-percent`}
          animate={controls}
          initial={isMobileOnly || !animations ? "visible" : "hidden"}
          variants={slideRightVariants}
          transition={{ delay: 0.1, duration: 0.8 }}
        >
          <AppetizeImage className="hwiaot__image" imageName={imageName} />
        </motion.div>
      </div>

      <style jsx global>{`
        .hwiaot__image img {
          max-height: 600px;
          width: auto;
        }
      `}</style>
    </section>
  );
};

HighlightWithImageAndOffsetText.propTypes = {
  className: string,
  title: string.isRequired,
  subtext: string.isRequired,
  ctaPrimary: shape({
    title: string,
    target: string,
  }).isRequired,
  imageName: string.isRequired,
  flipItems: bool,
  dark: bool,
  animations: bool,
};

HighlightWithImageAndOffsetText.defaultProps = {
  className: "",
  flipItems: false,
  dark: false,
  animations: true,
};

export default HighlightWithImageAndOffsetText;
